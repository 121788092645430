import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import * as styles from "./index.module.scss";
import AppListItem from "../components/app-list-item";
import SEO from "../components/seo";
import { products } from "../constants";

const IndexPage = () => {
  return (
    <main className={styles.index}>
      <section className={styles.logo}>
        <img className={styles.logo} src={logo} alt="Logo" />
      </section>
      <h1 className={styles.title}>图谱林</h1>
      <section className={styles.galleryWrapper}>
        <div className={styles.gallery}>
          {products.map((product, index) => (
            <AppListItem product={product} index={index} />
          ))}
        </div>
      </section>
      <footer className={styles.footer}>
        <div className={styles.card}>
          <a className='decoration' href="https://beian.miit.gov.cn" target="_blank">
            闽ICP备16005293号-1
          </a>
        </div>
        <div className={styles.card}>
          联系邮箱：<a className='decoration' href="mailto:tupulin@163.com">tupulin@163.com</a>
        </div>
      </footer>
    </main>
  );
};

export default IndexPage;

export const Head = () => (
  <SEO
    title="图谱林：在线工具提供者 - 图片处理"
    description="图谱林致力于为用户提供优秀的软件，同时确保高质量的用户体验以及美感。"
    keywords="图片处理,颜色,平面设计,贝塞尔曲线,css,图片压缩,官网,在线工具"
  />
);
